html, body {
  min-height: 100vh;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.theme-dierenkliniek {
  --theme-primary-color: #9e6614;
  --theme-border-radius: 0px;
  --theme-landing-url: url('../public/images/dierenkliniek/landing-content.png');
  --theme-navigation-url: url('../public/images/dierenkliniek/navigation.png');
  --theme-nav-padding-top: 30px;
  --theme-header-height: 158px;
  --theme-landing-page-height: 1931px;
}

.theme-londonvetclinic {
  --theme-primary-color: #63CDC9;
  --theme-border-radius: 25px;
  --theme-landing-url: url('../public/images/londonvetclinic/landing-content.png');
  --theme-navigation-url: url('../public/images/londonvetclinic/navigation.png');
  --theme-nav-padding-top: 6px;
  --theme-header-height: 162px;
  --theme-landing-page-height: 3929px;
}

h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  font-family: Montserrat, sans-serif;
  color: var(--theme-primary-color, blue);
}

header {
  background-image: var(--theme-navigation-url,);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: var(--theme-header-height);
}

section.landing-page {
  background-image: var(--theme-landing-url,);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: var(--theme-landing-page-height);
}

.nav-items {
  padding-top: var(--theme-nav-padding-top);
  text-align: right;
  max-width: 1454px;
  margin: 0 auto;
}

.nav-items a {
  margin: 0 0.62em;
  font-size: 14px;
}

.nav-items a:last-child {
  margin-right: 2em;
}

a, button {
  display: inline-block;
  text-decoration: none;
  color: rgb(38 34 35);
  font-family: Montserrat, sans-serif;
}

a:hover,
a:active {
  color: var(--theme-primary-color, blue);
}

button {
  padding: 0.62em;
  background-color: var(--theme-primary-color, blue);
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  padding-right: 1.2em;
  padding-left: 1.2em;
  border: 0;
  cursor: pointer;
  border-radius: var(--theme-border-radius, 5px);
}

button:hover {
  opacity: 0.8;
}
button:active {
  opacity: 0.6;
}

input, select {
  padding: 0.62em;
  border: 1px solid var(--theme-primary-color, blue);
  border-radius: var(--theme-border-radius, 5px);
  min-width: 300px;
}

iframe {
  border: none;
  width: 100%;
  height: calc(100vh - 158px);
}

.login,
.create-demo {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.login hr,
.create-demo hr {
  width: 40px;
  margin: 0;
  border: 2px solid var(--theme-primary-color, blue);
  margin-bottom: 18px;
}

.create-demo table {
  width: 100%;
}

.create-demo .key,
.create-demo .theme-option {
  background-color: #f5f5f5;
  padding: 2px 8px;
  border-radius: 6px;
  display: inline-block;
  /* font-weight: ; */
  font-family: 'Courier New', Courier, monospace;
}

.create-demo .theme-option {
  background-color: rgb(187, 219, 249);
  margin-right: 0.5em;
  /* font-size: 0.9em; */
}
.create-demo .theme-option:hover {
  color: inherit;
  opacity: 0.8;
}
.create-demo .theme-option:active {
  opacity: 0.6;
}
